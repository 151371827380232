import "styles/pages/404.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"

import Button from "components/Button/Button"

const TakkFyri = () => {
  const title = "Takk fyri!"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />

      <section className="page-404">
        <h1>
          <img
            src={require("assets/images/page-contact-thanks-image.svg").default}
            alt=""
          />{" "}
          Takk fyri!
        </h1>
        <p>Tín umsókn er nú send okkum.</p>
        <Button to="/">FAR TIL á FORSÍÐUNA</Button>
      </section>
    </Layout>
  )
}

export default TakkFyri
